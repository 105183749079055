// @flow

import type { Dispatch } from '../../types';

import { ellipsis, lighten, rem } from 'polished';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import * as config from '../../config';
import { Color } from '../../constants';
import { useAuthUser } from '../../hooks';

type Props = $ReadOnly<{|
  name: string,
  price: number | null,
  color: string,
  description: string,
  featuresPlus: string | void,
  features: string[],
|}>;

export default function PlanBox({
  name,
  price,
  color,
  description,
  featuresPlus,
  features,
}: Props) {
  const dispatch = useDispatch<Dispatch>();
  const authUser = useAuthUser();
  const priceParts = price ? (price / 100).toFixed(2).split('.') : null;

  return (
    <StyledWrapper>
      <StyledMainBordered>
        <StyledAreaColor color={color}>
          <StyledBlock invertBorder largePad>
            <StyledName>{name}</StyledName>
          </StyledBlock>
          {priceParts ? (
            <StyledPrice>
              <div className="row">
                <span className="med">£</span>
                <span className="big">{priceParts[0]}</span>
                <span className="med">.{priceParts[1]}</span>
              </div>
              <div className="per">each unit per month</div>
            </StyledPrice>
          ) : (
            <StyledPrice>
              <span className="free">Free</span>
            </StyledPrice>
          )}
        </StyledAreaColor>
        <StyledBlock>
          {price ? '30 Day Free Trial' : 'Free Forever'}
        </StyledBlock>
        <StyledBlock>
          <StyledAudience>{description}</StyledAudience>
        </StyledBlock>
        <StyledBlock noBorder>
          {price ? (
            <StyledButton
              href={`${
                config.SERVER_ROOT
              }/stripe/create-checkout-session?plan=${name.toLowerCase()}&bearer=${
                authUser.jwt
              }`}
            >
              Start Trial
            </StyledButton>
          ) : (
            <StyledButton
              onClick={() =>
                dispatch({
                  type: 'SET_OPTION_DISMISSED_SELECT_PLAN',
                  payload: true,
                })
              }
            >
              Get Started
            </StyledButton>
          )}
        </StyledBlock>
      </StyledMainBordered>
      <StyleBullets>
        <p>
          {featuresPlus
            ? `Everything in ${featuresPlus} plus`
            : `${name} features include`}
          :
        </p>
        <ul>
          {features.map((feature, i) => (
            <li key={i}>{feature}</li>
          ))}
        </ul>
      </StyleBullets>
    </StyledWrapper>
  );
}

const StyledWrapper = styled('div')`
  max-width: 300px;
  flex: 1;
  text-align: center;
`;

const StyledMainBordered = styled('div')`
  border: 1px ${Color.fainterBlue} solid;
  background-color: #fff;
`;

const StyledBlock = styled('div')`
  padding: ${(p) => rem(p.largePad ? 34 : 18)} 0;
  margin: 0 ${rem(18)};
  ${(p) =>
    !p.noBorder &&
    `border-bottom: 1px ${p.invertBorder ? '#fff' : Color.fainterBlue} solid`};
`;

const StyledName = styled.span`
  font-size: 21px;
  text-transform: uppercase;
`;

const StyledPrice = styled('div')`
  height: 160px;
  padding-bottom: 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .row {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
  }

  .big {
    font-size: ${rem(60)};
    margin: ${rem(-14)} ${rem(5)};
  }

  .med {
    font-size: ${rem(25)};
  }

  .per {
    margin-top: 10px;
    font-size: ${rem(14)};
  }

  .free {
    font-size: 34px;
  }
`;

const StyledAreaColor = styled('div')`
  background-color: ${(p) => p.color};
`;

const StyledButton = styled.a`
  display: block;
  height: ${rem(40)};
  line-height: ${rem(40)};
  border: 0;
  border-radius: ${rem(20)};
  font-size: ${rem(17)};
  font-weight: bold;
  color: #fff;
  ${ellipsis()};
  padding: 0 ${rem(60)};
  background-color: ${lighten(0.1, '#3389ff')};
  outline: 0;
  transition: background-color 0.25s;

  &:not([disabled]) {
    background-color: #3389ff;
    cursor: pointer;
    &:hover {
      background-color: ${lighten(0.05, '#3389ff')};
    }
  }
`;

const StyledAudience = styled.div`
  font-size: ${rem(16)};
`;

const StyleBullets = styled.div`
  p {
  }

  ul {
    text-align: left;
    padding: 0;
    list-style-type: none;
  }

  li {
    margin: 12px 0 12px 32px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: -1px;
      left: -32px;
      background-image: url(/img/subscription/check.svg);
    }
  }
`;
